<template>
  <div class="app-container">
    <div style="position: absolute;top:15px;right: 20px;z-index: 1;"><el-button size="small" type="primary" plain @click="toBank" icon="List">银行列表</el-button></div>
    <div>
      <el-tabs v-model="listQuery.type" @tab-click="handleTabClick">
          <el-tab-pane label="全部" :name="2"></el-tab-pane>
          <el-tab-pane label="已分配" :name="1"></el-tab-pane>
      </el-tabs>
    </div>
    <div class="top_select" >
      <div>
        <el-input size="small" v-model="listQuery.comName" style="width: 120px;"  @keyup.enter="getList" clearable placeholder="公司名称"></el-input>
				<el-input size="small" v-model="listQuery.orgName" style="width: 120px;margin: 0 5px;"  @keyup.enter="getList" clearable placeholder="机构名称"></el-input>
				<el-input size="small" v-model="listQuery.userName" style="width: 120px;"  @keyup.enter="getList" clearable placeholder="授权人"></el-input>
				<el-button size="small" type="primary" @click="getList" icon="Search">搜索</el-button>
        <el-date-picker
        style="width:130px;margin: 0 5px;"
        size="small"
        value-format="YYYY-MM-DD"
        v-model="listQuery.applyAt1"
        type="date"
        placeholder="请求日期"
        @change="getList"
        >
       </el-date-picker>
       <el-button type="danger" plain size="small" v-if="isAdmin == 1" @click="allotCancle()" :disabled="sels.length == 0">批量取消协助</el-button>
      </div>
    </div>
    <div style="width:1285px">
      <el-table :data="list" :height="contentStyleObj" style="width: 100%" border @selection-change="handleSelectionChange" v-loading="loading">
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column type="selection" align="center" width="50" />
        <el-table-column label="代理记账公司名称" width="350" prop="org_name">
        </el-table-column>
        <el-table-column label="公司名称" width="320">
          <template #default="scope">
            <span @click="gotoAccountBookSys(scope.row)" style="font-size:14px;cursor: pointer;">{{ scope.row.com_name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="授权人" align="center" width="120" prop="user_name">
        </el-table-column>
        <el-table-column label="请求时间" align="center" width="115">
          <template #default="scope">
            <span>{{$parseTime(scope.row.apply_at, "{y}-{m}-{d}")}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="330">
          <template #default="scope">
            <el-button size="small" @click="gotoAccountBookSys(scope.row)" type="success">进账簿</el-button>
            <el-button type="danger" plain size="small" @click="empower2(scope.row)">取消协助</el-button>
            <taxBureau :comId="scope.row.comId" @success="getList" />
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList"/>
      </div>
    </div>
  </div>
</template>

<script>
import { getRemoteHelperList,helpStart,helpOver } from "@/api/help";
import taxBureau from "@/components/loginTaxAlert/taxBureau.vue"
export default {
  name: "empower",
  components:{
    taxBureau
  },
  data() {
    return {
      list:[],
      sels:[],
      isAdmin:this.$store.getters['user/user'].isAdmin,
      listQuery:{
        applyAt1:"",
        page:1,
        limit:20,
        type:2
      },
      total:0,
      loading:false
    };
  },
  mounted() {
    this.contentStyleObj= this.$getHeight(250)
    this.getList()
  },
  methods: {
    getList(){
      this.loading = true
      getRemoteHelperList(this.listQuery).then(res=>{
        this.loading = false
        if(res.data.msg == "success"){
          this.list = res.data.data.list ? res.data.data.list : [];
          this.total = res.data.data.total
        }
      })
    },
    gotoAccountBookSys(row) {
      helpStart({comId: row.comId}).then(res=>{
        if (res.data.msg == "success") {
          this.$goEtax(row.comId)
        }
      })
    },
    handleTabClick(tab){
      this.listQuery.type = tab.props.name
      this.getList()
    },
    handleSelectionChange(e){
      this.sels = e;
    },
    empower2(row){
      this.$confirm('确认取消远程协助?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        helpOver({comIds:[row.comId]}).then(res=>{
          if (res.data.msg == "success") {
            this.$message({
              message: "已取消",
              type: "success"
            });
            this.getList()
          }
        })    
      })
    },
    allotCancle(){
      this.$confirm('确认批量取消远程协助?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let ids = []
        this.sels.map(v=>{
          ids.push(v.comId)
        })
        helpOver({comIds:ids}).then(res=>{
          if (res.data.msg == "success") {
            this.$message({
              message: "已取消",
              type: "success"
            });
            this.getList()
          }
        })
      })
    },
    // 银行列表
    toBank(){
      this.$router.push({
        name: "accountState",
      })
    }
  }
};
</script>
<style lang="scss" scoped>
.top_select{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
</style>
